import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
// import LoginButton from "./LoginButton";

export default function FleetControlHero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        backgroundRepeat: "no-repeat",
        mt: 6,
        background:
          "radial-gradient(ellipse at top, #0f2c45, transparent), radial-gradient(ellipse at bottom, #061e33, transparent)",

        ...theme.applyStyles("dark", {
          background:
            "radial-gradient(ellipse at top, #0f2c45, transparent), radial-gradient(ellipse at bottom, #061e33, transparent)",
        }),
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 18, sm: 20 },
          pb: { xs: 18, sm: 28 },
        }}
      >
        {/* <LoginButton /> */}
        <Stack
          spacing={2}
          useFlexGap
          sx={{ alignItems: "center", width: { xs: "100%", sm: "80%" } }}
        >
          <Typography
            variant="h1"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "column" },
              alignItems: "center",
              fontSize: "clamp(3rem, 10vw, 3.5rem)",
              fontWeight: "500",
            }}
          >
            {"Fleet Control System"} &nbsp;
          </Typography>
          <Typography
            variant="h4"
            sx={{ color: "text.primary", fontWeight: "300" }}
          >
            Explore our cutting-edge application, delivering high-quality fleet
            management solutions tailored to your needs. Elevate your experience
            with top-tier features and services.
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}
